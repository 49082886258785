import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  padding: 20px 20px 25px; 
  background-color: #FFF;
  box-shadow: 0 4px 24px 0 rgb(29 30 32 / 5%);

  ${({ article }) => article && css`
      padding: 0 !important;
  `}

  &:last-child {
    margin: 0;
    ${({ article }) => article && css`
      margin-top: 25px;
      ${breakpoint('lg')`
        margin-top: 35px;
      `}
    `}
  }

  ${breakpoint('md')`
    margin-bottom: 35px;
    padding: 25px 25px 30px;
  `}

  ${breakpoint('lg')`
    margin-bottom: 45px;
    padding: 30px 35px 40px;
  `}

  p:last-child {
    margin-bottom: 0;
  }

  strong, b {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.text};
  }

  ul, ol {
    margin: 20px 0;
    &:last-child {
      margin-bottom: 0;
    }
  }

  li {
    position: relative;
    font-size: 1.6rem;
    margin: 0 0 15px;
    line-height: 1.35;

    ${breakpoint('lg')`
      font-size: 1.8rem;
      margin: 0 0 18px;
    `}

    &:last-child {
      margin: 0 0 10px;
    }
    
    &::before {
      content: '';
      position: absolute;
      top: 8px;
      left: -25px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #000;
    }

    strong, b {
      font-weight: 700;
      color: ${({ theme }) => theme.colors.primary};
      &:nth-child(2) {
        color: ${({ theme }) => theme.colors.text};
      }
    }

    h2 {
      font-size: 1.8rem;
      margin-bottom: 10px;

      ${breakpoint('lg')`
        font-size: 2rem;
        margin-bottom: 15px;
      `}
    }
  }

  ol li::before {
    display: none;
  }

  ${({ noShadow }) => noShadow && css`
    box-shadow: none;
  `}
`;

export const StyledSectionIcon = styled.img`
  width: 100%;
  margin: 15px auto 25px;

  ${breakpoint('md')`
    max-width: 300px;
    margin: 15px auto 30px;
  `}

  ${breakpoint('lg')`
    margin: 15px auto 35px;
  `}

  ${({ smallerIcon }) => smallerIcon && css`
    width: 200px;
  `}
`;

export const StyledProductItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 0;
  gap: 50px;

  ${breakpoint('lg')`
    padding: 50px 0;
  `}
`;
export const StyledColumnsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  gap: 20px;

  ${breakpoint('md')`
    margin-top: 15px;
  `}

  ${breakpoint('lg')`
    flex-direction: row;
    margin-top: 25px;
    gap: 50px;
  `}
`;

export const StyledLeftColumn = styled.section`
  width: 100%;

  ${breakpoint('lg')`
    width: 50%;
  `}

  ${breakpoint('xl')`
    width: 45%;
  `}
`;

export const StyledRightColumn = styled.section`
  width: 100%;

  ${breakpoint('lg')`
    width: 50%;
  `}

  ${breakpoint('xl')`
    width: 55%;
  `}
`;

export const StyledWideColumn = styled.div`
  margin-top: 20px;

  ${breakpoint('md')`
    margin-top: 25px;
  `}

  ${breakpoint('lg')`
    margin-top: 30px;
  `}
`;
