import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { StyledMenu } from '../Navigation/Menu/Menu.styled';

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  height: 74px;
  padding: 10px 0;
  background-color: #FFF;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  border-top: 2px solid ${({ theme }) => theme.colors.primary};
  z-index: 998;

  ${breakpoint('lg')`
    height: 80px;
  `}
  
  ${({ isSticky }) => isSticky && css`
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;

    ${StyledMenu} {
      top: 74px;
    }
  `};
`;

export const StyledHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledHeaderLogoLink = styled.a`
  width: 140px;
  height: 54px;
  flex-shrink: 0;
  display: block;
  position: relative;

  ${breakpoint('lg')`
    width: 172px;
    height: 65px;
  `}
`;

export const StyledHeaderLogoImg = styled.img`
  width: 100%;
`;
