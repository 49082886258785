import React from 'react';
import PropTypes from 'prop-types';
import { StyledHeading } from './Heading.styled';

const Heading = ({
  title, headingLevel, centered, highlightedLastWord, highlightedSecondWord,
}) => {
  const validHeadingLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
  const safeHeading = headingLevel ? headingLevel.toLowerCase() : '';
  const Tag = validHeadingLevels.includes(safeHeading) ? safeHeading : 'p';

  const words = title.split(' ');

  const changeLastWord = () => {
    const lastWord = words.pop();
    return `${words.join(' ')} <span class='orange'>${lastWord}</span>`;
  };

  const highlightOnlySecondWord = () => {
    for (let i = 0, len = 2; i < len; i++) {
      words[i] = i % 2 === 1 ? `<span class="orange">${words[i]}</span>` : words[i];
    }
    return words.join(' ');
  };

  const changeEverySecondWord = () => {
    for (let i = 0, len = words.length; i < len; i++) {
      words[i] = i % 2 === 1 ? `<span class="orange">${words[i]}</span>` : words[i];
    }
    return words.join(' ');
  };

  const handleHighlightedWords = () => {
    if (highlightedLastWord) {
      return changeLastWord();
    } if (highlightedSecondWord) {
      return highlightOnlySecondWord();
    }
    return changeEverySecondWord();
  };

  return (
    <StyledHeading
      as={Tag}
      centered={centered}
      dangerouslySetInnerHTML={{
        __html: handleHighlightedWords(),
      }}
    />
  );
};

Heading.propTypes = {
  title: PropTypes.string.isRequired,
  headingLevel: PropTypes.string.isRequired,
  centered: PropTypes.bool,
  highlightedLastWord: PropTypes.bool,
  highlightedSecondWord: PropTypes.bool,
};

Heading.defaultProps = {
  centered: false,
  highlightedLastWord: false,
  highlightedSecondWord: false,
};

export default Heading;
