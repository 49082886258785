import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { StyledFigure, StyledFigcaption } from './Figure.styled';

const Figure = ({
  image, title, alt, productCategoryPage, smaller, ...props
}) => (
  <StyledFigure
    productCategoryPage={productCategoryPage}
    smaller={smaller}
    {...props}
  >
    <GatsbyImage image={image} alt={alt} />
    <StyledFigcaption>{title}</StyledFigcaption>
  </StyledFigure>
);

Figure.propTypes = {
  image: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired,
  title: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  productCategoryPage: PropTypes.bool,
  smaller: PropTypes.bool,
};

Figure.defaultProps = {
  productCategoryPage: false,
  smaller: false,
};

export default Figure;
