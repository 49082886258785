import React from 'react';
import PropTypes from 'prop-types';
import { StyledPageContainer, StyledContainer } from './Container.styled';

const Container = ({ pageContainer, children }) => (
  pageContainer ? (
    <StyledPageContainer
      pageContainer={pageContainer}
    >
      {children}
    </StyledPageContainer>
  ) : (
    <StyledContainer>
      {children}
    </StyledContainer>
  )
);

Container.propTypes = {
  pageContainer: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Container.defaultProps = {
  pageContainer: false,
};

export default Container;
