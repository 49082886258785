import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const StyledPostHero = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 35vh;

  ${breakpoint('lg')`
    height: 450px;
  `}

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background-color: rgba(15,15,15,.5);
    z-index: 1;
  }

  .gatsby-image-wrapper {
    width: 100% !important;
    height: 100% !important;

    img {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      object-fit: cover;
    }
  }
`;

export const StyledPostHeader = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
`;

export const StyledPostTitle = styled.h1`
  position: relative;
  color: #ffffff;
  text-align: center;
  margin: 0;
  font-size: 2.5rem;
  line-height: 1.2;
  z-index: 2;

  ${breakpoint('md')`
    font-size: 3rem;
  `}

  ${breakpoint('lg')`
    font-size: 3.5rem;
  `}
`;

export const StyledPostAuthorWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledPostAuthorIcon = styled.img`
  position: relative;
  margin: 10px;
  width: 30px;
  height: 30px;
  display: inline-block;
  border: 2px solid #EA622B;
  padding: 3px;
  border-radius: 50%;
  z-index: 1;

  ${breakpoint('lg')`
    width: 35px;
    height: 35px;
    padding: 4px;
  `}
`;

export const StyledPostAuthorTitle = styled.p`
position: relative;
  color: #EA622B;
  font-size: 1.4rem;
  font-weight: bold;
  z-index: 1;

  ${breakpoint('lg')`
    font-size: 1.6rem;
  `}
`;

export const StyledPostContent = styled.div`
  padding: 20px 20px 25px;

  ${breakpoint('md')`
    padding: 25px 25px 30px;
  `}

  ${breakpoint('lg')`
    padding: 30px 35px 40px;
  `}

  img {
    text-align: center;
  }
`;
