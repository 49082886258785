import React from 'react';
import PropTypes from 'prop-types';
import { StyledDownloadFile, StyledTitle, StyledLink } from './DownloadFilestyled';

const DownloadFile = ({
  title, fileSrc, fileType, ...props
}) => (
  <StyledDownloadFile {...props}>
    <StyledLink href={fileSrc} target="_blank" dowload>
      <span>Pobierz</span>
      <span>{fileType}</span>
    </StyledLink>
    <StyledTitle>{title}</StyledTitle>
  </StyledDownloadFile>

);

DownloadFile.propTypes = {
  title: PropTypes.string.isRequired,
  fileSrc: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
};

export default DownloadFile;
