import React from 'react';
import PropTypes from 'prop-types';
import { StyledOverlay } from './Overlay.styled';

const Overlay = ({ isVisible }) => (
  <StyledOverlay isVisible={isVisible} />
);

Overlay.propTypes = {
  isVisible: PropTypes.bool,
};

Overlay.defaultProps = {
  isVisible: false,
};

export default Overlay;
