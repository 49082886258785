/* Plugins */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */
import {
  StyledContactForm,
  StyledInput,
  StyledInputLabel,
  StyledInputField,
  StyledTextArea,
  StyledSubmitButton,
} from './ContactForm.styled';

const ContactForm = ({
  onSubmit, onChange,
}) => (
  <StyledContactForm
    method="post"
    name="contactForm"
    data-netlify="true"
    netlify-honeypot="bot-field"
    data-netlify-recaptcha="true"
    onSubmit={onSubmit}
  >
    {/* Honeypot field */}
    <StyledInput className="hidden">
      <StyledInputField name="bot-field" />
      <StyledInputLabel>Anty bot</StyledInputLabel>
    </StyledInput>

    {/* Name */}
    <StyledInput>
      <StyledInputField name="name" type="text" placeholder=" " onChange={onChange} />
      <StyledInputLabel>Imię i nazwisko</StyledInputLabel>
    </StyledInput>
    {/* Email */}
    <StyledInput>
      <StyledInputField name="email" type="email" placeholder=" " onChange={onChange} />
      <StyledInputLabel>Adres email</StyledInputLabel>
    </StyledInput>
    {/* Phone */}
    <StyledInput>
      <StyledInputField name="tel" type="tel" placeholder=" " onChange={onChange} />
      <StyledInputLabel>Numer telefonu</StyledInputLabel>
    </StyledInput>
    {/* Message */}
    <StyledInput>
      <StyledTextArea name="message" placeholder=" " onChange={onChange} />
      <StyledInputLabel>Wiadomość</StyledInputLabel>
    </StyledInput>
    <StyledSubmitButton type="submit">Wyślij</StyledSubmitButton>
  </StyledContactForm>
);

ContactForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

ContactForm.defaultProps = {
  // productCategoryPage: false,
  // smaller: false,
};

export default ContactForm;
