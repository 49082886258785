import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledContactBox,
  StyledContactIcon,
  StyledContactTitle,
  StyledContactText,
  StyledContactLink,
} from './ContactBox.styled';

const Section = ({
  iconSrc,
  iconAlt,
  title,
  description,
  linkText,
  secondLinkText,
  linkTarget,
  secondLinkTarget,
  ...props
}) => (
  <StyledContactBox {...props}>
    {iconSrc && (
      <StyledContactIcon src={iconSrc} alt={iconAlt} />
    )}
    {title && (
      <StyledContactTitle>{title}</StyledContactTitle>
    )}
    {description && (
      <StyledContactText>{description}</StyledContactText>
    )}
    {linkText && (
      <StyledContactLink href={linkTarget} target="_blank">{linkText}</StyledContactLink>
    )}
    {secondLinkText && (
      <StyledContactLink href={secondLinkTarget} target="_blank">{secondLinkText}</StyledContactLink>
    )}
  </StyledContactBox>
);

Section.propTypes = {
  iconSrc: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  iconAlt: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  linkText: PropTypes.string,
  linkTarget: PropTypes.string,
  secondLinkText: PropTypes.string,
  secondLinkTarget: PropTypes.string,
};

Section.defaultProps = {
  iconSrc: false,
  iconAlt: '',
  title: '',
  description: '',
  linkText: '',
  linkTarget: '',
  secondLinkText: '',
  secondLinkTarget: '',
};

export default Section;
