import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton } from './Button.styled';

const Button = ({
  title, primary, secondary, megaMenu, arrowActive, ...props
}) => (
  <StyledButton
    type="button"
    primary={primary}
    secondary={secondary}
    megaMenu={megaMenu}
    arrowActive={arrowActive}
    {...props}
  >
    {title}
  </StyledButton>
);

Button.propTypes = {
  title: PropTypes.string.isRequired,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  megaMenu: PropTypes.bool,
  arrowActive: PropTypes.bool,
};

Button.defaultProps = {
  primary: false,
  secondary: false,
  megaMenu: false,
  arrowActive: false,
};

export default Button;
