import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const StyledWrapper = styled.div`
  width: 100%;
  padding: 50px 0;
  margin: 0;
  overflow: hidden;

  ${breakpoint('md')`
    padding: 80px 0;
  `}

  ${breakpoint('lg')`
    padding: 120px 0;
  `}

  h1 {
    margin-top: 0;
  }

  article:only-child {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  ${({ backgroundColor }) => backgroundColor && css`
    background-color: ${backgroundColor};
  `}

  ${({ noPadding }) => noPadding && css`
    padding: 0 !important;
  `}
`;
