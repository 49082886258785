import * as React from 'react';
import PropTypes from 'prop-types';
import Gallery from '@browniebroke/gatsby-image-gallery';
import { Heading } from '../../components';
import { StyledProductGallery } from './ProductGallery.styled';

const ProductGallery = ({ images, title }) => (
  <StyledProductGallery>
    <Heading headingLevel="p" title={title} highlightedLastWord />
    <Gallery images={images} gutter="1rem" rowMargin="-10" colWidth="100" mdColWidth="50" />
  </StyledProductGallery>
);

ProductGallery.propTypes = {
  images: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  title: PropTypes.string,
};

ProductGallery.defaultProps = {
  title: '',
};

export default ProductGallery;
