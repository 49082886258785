import { Link } from 'gatsby';
import Image from 'gatsby-image';
import styled from 'styled-components';

export const StyledPostCardWrapper = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 4px 24px 0 rgb(29 30 32 / 5%);
  background-color: #fff;
  transition: transform .3s ease;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);

    h2{
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const StyledPostCardInfo = styled.div`
  padding: 20px;
  position: relative; 
  background-color: #fff;

  & > :not(h2, p:first-of-type) {
    display: none;
  }

  // post description 100 characters
  p:first-of-type {
    font-weight: 400;
    color: #858484;
    margin: 0;
  }
`;

export const StyledPostCardTitle = styled.h2`
  color: #000;
  margin-bottom: 10px;
  transition: color .3s ease;
`;

export const StyledImage = styled(Image)`
  width: 100%;
  height: 250px;
  object-fit: cover;
`;
