import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const StyledMegaMenu = styled.div`
  position: relative;
  left: 0;
  height: 0;
  width: 100%;
  background-color: #FFF;
  visibility: hidden;
  overflow: hidden;

  ${breakpoint('lg')`
    margin-top: 28px;
    position: absolute;
    box-shadow: 0 24px 24px -2px rgb(0 0 0 / 15%);
    border-top: 2px solid #F3F4F5;
    border-bottom: 2px solid #F3F4F5;
  `}

  ${({ isVisible }) => isVisible && css`
    height: auto;
    visibility: visible;
  `}
`;

export const StyledMegaMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint('lg')`
    flex-direction: row;
  `}
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 80px 40px 0;
  gap: 15px;

  ${breakpoint('lg')`
     width: 25%;
     padding: 40px;
     padding-left: 0;
     border-right: 2px solid #F3F4F5;
  `}
`;

export const StyledMegaMenuContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
  overflow-x: auto;

  ${breakpoint('lg')`
     width: 75%;
     padding: 40px;
     padding-right: 0;
     overflow: hidden;
  `}
`;

export const StyledMegaMenuContent = styled.div`
  width: 100%;
  display: none;
  padding: 0 10px;
  justify-content: space-between;
  gap: 20px;

  ${({ active }) => active && css`
    display: flex;
  `}

  ${breakpoint('lg')`
     padding: 0;
  `}
`;
