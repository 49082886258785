import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import ChevronDownIcon from '../../../../assets/images/icons/chevron-down-icon.svg';
import ChevronUpIcon from '../../../../assets/images/icons/chevron-up-icon.svg';

export const StyledMenu = styled.div`
  width: 100%; 
  height: 0;
  position: fixed;
  visibility: hidden;
  background-color: #FFF;
  top: 104px;
  left: 0;
  padding: 0 20px;
  box-shadow: 0 24px 24px -2px rgb(0 0 0 / 15%);
  overflow-y: auto;

  ${({ open }) => open && css`
    bottom: 0;
    height: auto;
    visibility: visible;
    border-top: 2px solid #F3F4F5;
  `};

  ${breakpoint('lg')`
    height: auto;
    position: static;
    display: flex;
    flex-direction: row;
    top: 0;
    padding: 0;
    border: 0;
    visibility: visible;
    overflow: hidden;
  `}
`;

export const StyledMenuList = styled.ul`
  width: 100%;
  position: relative;
  display: block;
  padding: 40px 0;
  transition: all 0.35s ease;
  overflow-y: initial;

  ${breakpoint('lg')`
    position: static;
    display: flex;
    padding: 0;
    transition: none;
    overflow: hidden
  `}
 `;

export const StyledMenuDropdown = styled.button`
  display: inline-block;
  position: relative;
  padding-right: 20px;
  border: 0;
  background-color: transparent;

  &::after {
    content: '';
    position: absolute;
    background-image: url(${ChevronDownIcon});
    background-size: contain;
    background-repeat: no-repeat;
    top: 50%;
    right: 2px;
    width: 15px;
    height: 15px;
    transform: translateY(-50%);
  }

  &.active {
    &::after {
      background-image: url(${ChevronUpIcon});
    }
  }
`;

export const StyledMenuItem = styled.li`
  font-size: 1.6rem;
  margin: 0 0 25px 0;
  white-space: nowrap;
  position: static;

  ${breakpoint('lg')`
    font-size: 1.4rem;
    margin: 0 18px 0 0;
  `}

  &::before {
    display: none;
  }

  &:last-child {
    margin: 0;
  }

  .menu-link, 
  ${StyledMenuDropdown} {
    font-size: inherit;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.text};
    text-transform: uppercase;
    cursor: pointer;
  
    &:hover {
      border-bottom: 1px solid;
    }

    &.active {
      border-bottom: 0;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
