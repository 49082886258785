import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const StyledPriceList = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 20px;
  
  ${({ wide }) => wide && css`
    margin: 20px 0;
    ${breakpoint('lg')`
      width: 80%;
      margin: 20px auto;
    `}
  `};
`;

export const StyledPriceListTable = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr; 
  align-items: center;
  text-align: left;
  max-width: 100%;
  font-weight: 600;
  margin-bottom: 25px;
  gap: 12px;
`;

export const StyledListItem = styled.span`
  color: #FFF;
  padding-right: 10px;

  &:nth-child(1) {
    text-transform: uppercase;
    margin-bottom: 10px;
    padding: 0;
  }

  ${breakpoint('md')`
    font-size: 1.8rem;
  `}
`;

export const StyledPrice = styled.span`
  text-align: center;
  background-color: white;
  color: ${({ theme }) => theme.colors.primary};
  padding: 3px 0;

  &:nth-child(2) {
    color: #FFF;
    background-color: transparent;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  ${breakpoint('md')`
    font-size: 1.8rem;
  `}

  &.noBackground {
    color: #fff;
    background-color: transparent;
  }
`;

export const StyledLabel = styled.span`
  display: block;
  text-align: center;
  background-color: white;
  color: ${({ theme }) => theme.colors.primary};
  grid-column: 1 / -1;
  font-weight: 700;
  padding: 5px 0;

  & + span {
    display: none;
  }

  &:not(:first-child) {
    margin-top: 20px;
  }
`;

export const StyledHint = styled.p`
  color: white;
  font-size: 1.5rem;
  white-space: pre-line;
`;

export const StyledErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border: 1px solid #000;
  margin-bottom: 20px;
  padding: 20px;
`;

export const StyledErrorText = styled.span`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;

  ${breakpoint('md')`
    font-size: 1.8rem;
  `}
`;

export const StyledErrorButton = styled.button`
  color: white;
  background-color: #184e77;
  font-size: 1.5rem;
  min-width: 180px;
  font-weight: 600;
  margin-top: 20px;
  padding: 12px 18px;
  box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 40%);
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 0;

  &:hover {
    box-shadow: 0px 4px 6px 0px rgb(0 0 0 / 40%);
  }
`;
