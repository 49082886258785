import React from 'react';
import { Link } from 'gatsby';
import Container from '../Container/Container';
import { footerLinks } from './Footer.links';
import { theme } from '../../../styles/theme';
import {
  StyledFooter,
  StyledFooterLinksCol,
  StyledFooterLinkCategory,
  StyledFooterLink,
  StyledFooterCopyright,
  StyledFooterCompanyDetails,
} from './Footer.styled';

const currentYear = new Date().getFullYear();

const Footer = () => (
  <StyledFooter>
    <Container>
      {/* columns based on footer links */}
      {footerLinks.map(({ category, links, paths }) => (
        <StyledFooterLinksCol key={category}>
          <StyledFooterLinkCategory>{category}</StyledFooterLinkCategory>
          {links.map((link, index) => (
            <StyledFooterLink
              as={Link}
              key={link}
              to={paths[index]}
            >
              {link}
            </StyledFooterLink>
          ))}
        </StyledFooterLinksCol>
      ))}

      {/* Contact column */}
      <StyledFooterLinksCol>
        <StyledFooterLinkCategory>Kontakt</StyledFooterLinkCategory>
        <StyledFooterLink href={`mailto:${theme.contacts.email}`}>{theme.contacts.email}</StyledFooterLink>
        <StyledFooterLink href={`tel:${theme.contacts.phone}`}>{theme.contacts.phone}</StyledFooterLink>
      </StyledFooterLinksCol>

      {/* Company details */}
      <StyledFooterCompanyDetails>
        {`${theme.contacts.companyName} | ${theme.contacts.NIP} | ${theme.contacts.address}, 
        ${theme.contacts.postalCode}, ${theme.contacts.province}`}
      </StyledFooterCompanyDetails>

      {/* Copyright section */}
      <StyledFooterCopyright>
        <span>
          <StyledFooterLink href="https://banerbunny.pl">banerbunny.pl</StyledFooterLink>
          {` © ${currentYear}`}
        </span>
        <StyledFooterLink
          as={Link}
          to="/polityka-prywatnosci/"
        >
          Polityka prywatności
        </StyledFooterLink>
      </StyledFooterCopyright>
    </Container>
  </StyledFooter>
);

export default Footer;
