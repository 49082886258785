/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import {
  StyledPostHero,
  StyledPostHeader,
  StyledPostContent,
  StyledPostTitle,
  StyledPostAuthorWrapper,
  StyledPostAuthorTitle,
  StyledPostAuthorIcon,
} from './PostView.styled';

export const query = graphql`
  query {
    file(name: {eq: "author-icon"}) {
      publicURL
    }
  }
`;

const Preview = ({
  title, featureImage, author, children,
}) => {
  const data = useStaticQuery(query);

  return (
    <>
      <StyledPostHero>
        <StyledPostHeader>
          <StyledPostAuthorWrapper>
            <StyledPostAuthorIcon src={data.file.publicURL} alt="baner-bunny" />
            <StyledPostAuthorTitle>{author}</StyledPostAuthorTitle>
          </StyledPostAuthorWrapper>
          <StyledPostTitle>{title}</StyledPostTitle>
        </StyledPostHeader>
        <Image fixed={featureImage} alt={title} />
      </StyledPostHero>
      <StyledPostContent>{children}</StyledPostContent>
    </>
  );
};

export default Preview;
