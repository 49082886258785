import styled from 'styled-components';
// import breakpoint from 'styled-components-breakpoint';
import { theme } from '../../../styles/theme';

// const colorText = '#706F6F';
const colorBorder = '#706F6F';
const colorBackground = 'white';
const colorAccent = theme.colors.primary;

const sizeBezel = '.5rem';

export const StyledContactForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const StyledInput = styled.label`
  position: relative;
  margin-bottom: 25px;

  &.hidden {
    display: none;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledInputLabel = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  padding: calc(${sizeBezel} * 0.75) calc(${sizeBezel} * .5);
  margin: calc(${sizeBezel} * 0.75 + 3px) calc(${sizeBezel} * .5);
  white-space: nowrap;
  transform: translate(0, 0);
  transform-origin: 0 0;
  background: ${colorBackground};
  transition: transform 120ms ease-in;
  font-weight: 600;
  line-height: 1.2;
`;

export const InputStyle = `
  width: 100%;
  display: block;
  padding: 0 8px;
  border: 1px solid;
  border-color: ${colorBorder};
  box-sizing: border-box;
  font-size: 1.6rem;
  
  &:focus,
  &:not(:placeholder-shown) {
    & + ${StyledInputLabel} {
      transform: translate(.25rem, -65%) scale(.8);
      color: ${colorAccent};
      border-radius: 0;
    }
  }
`;

export const StyledInputField = styled.input`
  ${InputStyle}
  min-height: 45px;
`;

export const StyledTextArea = styled.textarea`
 ${InputStyle}
`;

export const StyledSubmitButton = styled.button`
  width: 150px;
  display: block;
`;
