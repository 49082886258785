import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { StyledSection, StyledSectionIcon } from './Section.styled';

const Section = ({
  noShadow, iconSrc, smallerIcon, iconAlt, children, article, ...props
}) => (
  <StyledSection noShadow={noShadow} article={article} {...props}>
    {iconSrc && (
      <StyledSectionIcon
        as={GatsbyImage}
        image={iconSrc}
        alt={iconAlt}
        // optional props for smaller icon (e.g. for rollups)
        smallerIcon={smallerIcon}
      />
    )}
    {children}
  </StyledSection>
);

Section.propTypes = {
  noShadow: PropTypes.bool,
  iconSrc: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
  smallerIcon: PropTypes.bool,
  iconAlt: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  article: PropTypes.bool,
};

Section.defaultProps = {
  noShadow: false,
  iconSrc: false,
  smallerIcon: false,
  iconAlt: '',
  article: false,
};

export default Section;
