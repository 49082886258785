import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Container from '../Container/Container';
import Navigation from '../Navigation/Navigation';
import {
  StyledHeader,
  StyledHeaderContent,
  StyledHeaderLogoLink,
  StyledHeaderLogoImg,
} from './Header.styled';

const query = graphql`
  query {
    file(name: {eq: "logo-gray"}) {
      publicURL
    }
  }
`;

const Header = ({ isSticky }) => {
  const data = useStaticQuery(query);

  return (
    <StyledHeader isSticky={isSticky}>
      <Container>
        <StyledHeaderContent>
          <StyledHeaderLogoLink as={Link} to="/" title="BanerBunny">
            <StyledHeaderLogoImg src={data.file.publicURL} alt="Drukarnia wielkoformatowa - Baner Bunny" width="140" height="140" />
          </StyledHeaderLogoLink>
          <Navigation />
        </StyledHeaderContent>
      </Container>
    </StyledHeader>
  );
};

Header.propTypes = {
  isSticky: PropTypes.bool,
};

Header.defaultProps = {
  isSticky: false,
};

export default Header;
