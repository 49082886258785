import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const iconSize = 70;
const iconPosition = 120;
const iconSVGSize = 36;

export const WhatsAppWidgetWrapper = styled.div`
  position: fixed;
  font-weight: 600;
  margin-top: 20px;
  padding: 12px 18px;
  box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 40%);
  z-index: 997;

  & > div {
    
    // WhatsApp window
    & > div:nth-child(1) {
      width: 70%;
      max-width: 360px;       
      bottom: ${iconPosition}px;
      left: 25px;
      
      ${breakpoint('lg')`
        width: 360px;
        left: auto;
        right: ${iconPosition}px;
      `}

      & > div:nth-child(2) > div {
        max-width: calc(100% - 20px);
        ${breakpoint('sm')`
          max-width: calc(100% - 120px);
        `}
      }
    }

    // WhatsApp button
    & > div:nth-child(2) {
      width: ${iconSize}px;
      height: ${iconSize}px;
      bottom: 25px;
      left: 25px;
      background: #30a340;

      ${breakpoint('lg')`
        left: auto;
        right: ${iconPosition}px;
      `}

      // WhatsApp icon
      svg {
        fill: white;
        width: ${iconSVGSize}px;
        height: ${iconSVGSize}px;
      }
    } 
  }
`;
