/* eslint-disable react/prop-types */
import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import {
  StyledPostCardWrapper,
  StyledPostCardInfo,
  StyledPostCardTitle,
  StyledImage,
} from './PostCard.styled';

const Preview = ({
  title, teaser, image, slug,
}) => (
  <StyledPostCardWrapper to={`/blog/${slug}`}>
    <StyledImage fluid={image} />
    <StyledPostCardInfo>
      <StyledPostCardTitle>{title}</StyledPostCardTitle>
      <Markdown rehypePlugins={[rehypeRaw]} children={`${teaser.substr(0, 120)}...`} />
    </StyledPostCardInfo>
  </StyledPostCardWrapper>
);

export default Preview;
