import React from 'react';
import PropTypes from 'prop-types';
import { StyledBurger } from './Burger.styled';

const Burger = ({ open, setOpen }) => (
  <StyledBurger
    open={open}
    onClick={() => setOpen(!open)}
    aria-label="Hamburger Menu"
  >
    <span />
    <span />
    <span />
  </StyledBurger>
);

Burger.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

Burger.defaultProps = {
  open: false,
  setOpen: () => {},
};

export default Burger;
