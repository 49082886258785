/* eslint-disable max-len */
/* eslint-disable react/jsx-no-constructed-context-values */

/* Plugins */
import React, { useReducer } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import CookieConsent from 'react-cookie-consent';
import { ThemeProvider } from 'styled-components';

/* Store */
import Reducer, { initialState } from '../store/Reducer';
import { Context } from '../store/Context';

/* Global variables */
import { theme } from '../styles/theme';

/* Components */
import {
  PageWrapper,
  Container,
  PreHeader,
  Header,
  Footer,
  Overlay,
} from '../components/components';
import { CallPage, WhatsApp } from '../components/Communication';

/* Styles */
import GlobalStyle from '../styles/globalStyles';

const MainLayout = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  const { stickyHeader, overlayVisible } = state;

  return (
    <Context.Provider value={[state, dispatch]}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <PageWrapper>
          <PreHeader />
          <Header isSticky={stickyHeader} />
          <Container pageContainer>
            {children}
          </Container>
          <Footer />
        </PageWrapper>

        {/* whatsapp widget */}
        <WhatsApp />

        {/* call page component */}
        {typeof window !== 'undefined' && <CallPage />}

        {/* overlay component */}
        <Overlay isVisible={overlayVisible} />

        {/* cookies bar */}
        <CookieConsent
          location="bottom"
          buttonText="OK"
          cookieName="cookies"
          containerClasses="cookies-container"
          contentClasses="cookies-text"
          buttonClasses="cookies-button"
          expires={365}
        >
          Klikając „OK”, wyrażasz zgodę na przechowywanie plików cookie na Twoim urządzeniu w celu poprawy nawigacji w witrynie,
          analizowania korzystania z witryny oraz lepszego dopasowania treści marketingowych.
          {' '}
          <Link to="/polityka-prywatnosci">Polityka prywatności</Link>
        </CookieConsent>
      </ThemeProvider>
    </Context.Provider>
  );
};

MainLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default MainLayout;
