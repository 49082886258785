export const footerLinks = [
  // banery
  {
    category: 'Banery',
    links: [
      'Baner standard',
      'Baner powlekany',
      'Baner odblaskowy',
      'Siatka mesh',
    ],
    paths: [
      '/banery/baner-standard/',
      '/banery/baner-powlekany/',
      '/banery/baner-odblaskowy/',
      '/banery/siatka-mesh/',
    ],
  },
  // kasetony
  {
    category: 'Kasetony',
    links: [
      'Kaseton dibond',
      'Kaseton plexi',
      'Kaseton vinyl',
      'Kaseton nieregularny',
    ],
    paths: [
      '/kasetony/kaseton-dibond/',
      '/kasetony/kaseton-plexi/',
      '/kasetony/kaseton-vinyl/',
      '/kasetony/kaseton-nieregularny/',
    ],
  },
  // ścianki
  {
    category: 'Ścianki',
    links: [
      'Ścianka tekstylna prosta',
      'Ścianka tekstylna łukowa',
      'Ścianka banerowa',
    ],
    paths: [
      '/scianki/scianka-tekstylna-prosta/',
      '/scianki/scianka-tekstylna-lukowa/',
      '/scianki/scianka-banerowa/',
    ],
  },
  // rollupy
  {
    category: 'Rollupy',
    links: [
      'Rollup standard',
      'Rollup dwustronny',
      'Rollup premium',
    ],
    paths: [
      '/rollupy/rollup-standard/',
      '/rollupy/rollup-dwustronny/',
      '/rollupy/rollup-premium/',
    ],
  },
];
