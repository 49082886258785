import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useGoogleSheets from 'use-google-sheets';
import {
  Heading, Loader, Article, CTAButton,
} from '../../components';
import * as messages from './PriceList.messages';
import {
  StyledErrorWrapper,
  StyledErrorText,
  StyledErrorButton,
  StyledPriceList,
  StyledPriceListTable,
  StyledListItem,
  StyledPrice,
  StyledLabel,
  StyledHint,
} from './PriceList.styled';

const PriceList = ({
  title, customTitle, hint, wide, range, divided,
}) => {
  const {
    data, loading, error, refetch,
  } = useGoogleSheets({
    apiKey: 'AIzaSyCGfHkFuJOsqTjK_efHlqOPeiIlTeiW4oo',
    sheetId: '1AhWVuzjhU5Wo33eb9fpjHgxFQo9JjBchhzqGmoRd7yA',
    sheetsNames: [range],
  });
  const [sheetData, setSheetData] = useState(null);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) setSheetData(data[0]);
    return () => { isMounted = false; };
  }, [data]);

  return (
    <Article>
      {title && (
        <Heading
          headingLevel="h2"
          title={`${title} cena`}
          highlightedLastWord
        />
      )}
      {customTitle && (
        <Heading
          headingLevel="h2"
          title={customTitle}
          centered
        />
      )}
      <StyledPriceList wide={wide}>
        {loading && <Loader /> }
        {error && (
          <StyledErrorWrapper>
            <StyledErrorText>{messages.error}</StyledErrorText>
            <StyledErrorButton type="button" onClick={refetch}>{messages.loadPrices}</StyledErrorButton>
          </StyledErrorWrapper>
        )}
        {sheetData && (
          <StyledPriceListTable divided={divided}>
            {Object.entries(sheetData.data).map(([, sheetRow]) => (
              Object.entries(sheetRow).map(([, sheetCell], index) => (
                <React.Fragment key={sheetCell}>
                  {sheetCell.includes('SZEROKOŚĆ') && (
                    <StyledLabel>{sheetCell}</StyledLabel>
                  )}
                  {index % 2 === 0 && (
                    <StyledListItem>{!sheetCell.includes('SZEROKOŚĆ') && sheetCell}</StyledListItem>
                  )}
                  {index % 2 === 1 && (
                    <StyledPrice className={sheetCell.includes('CENA') && 'noBackground'}>
                      {sheetCell.replace(/\s*[.,]\d+/g, '')}
                    </StyledPrice>
                  )}
                </React.Fragment>
              ))
            ))}
          </StyledPriceListTable>
        )}
        <StyledHint>{hint}</StyledHint>
        <CTAButton title={messages.submitYourOrder} />
      </StyledPriceList>
    </Article>
  );
};

PriceList.propTypes = {
  title: PropTypes.string,
  customTitle: PropTypes.string,
  hint: PropTypes.string,
  wide: PropTypes.bool,
  range: PropTypes.string.isRequired,
  divided: PropTypes.bool,
};

PriceList.defaultProps = {
  title: '',
  customTitle: '',
  hint: '',
  wide: false,
  divided: false,
};

export default PriceList;
