import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../../../styles/theme';
import { StyledCTA } from './CTAButton.styled';

const CTAButton = ({ title }) => (
  <StyledCTA
    href={`mailto:${theme.contacts.email}`}
    onclick="return gtag_report_conversion('https://banerbunny.pl');"
  >
    {title}
  </StyledCTA>
);

CTAButton.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CTAButton;
