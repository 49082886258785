import styled, { keyframes } from 'styled-components';
import ChevronRightIcon from '../../../../assets/images/icons/chevron-right-icon.svg';

const breatheAnimation = keyframes`
 50% { transform: translateX(10px); }
`;

export const StyledCTA = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #39c161;
  text-align: center;
  color: #fff;
  border: 0;
  padding: 16px;
  font-size: 1.8rem;
  font-weight: 600;
  margin-top: 25px;
  box-shadow:  0px 2px 6px 0px rgba(0,0,0,0.4);
  transition: 
    background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1), 
    box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1), 
    transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    background-color: #1e9e3d;
    box-shadow:  0px 4px 6px 0px rgba(0,0,0,0.4);
    &::after {
      animation: ${breatheAnimation} 1.5s infinite;
    }
  }

  &::after {
    content: '';
    display: block;
    background-image: url(${ChevronRightIcon});
    background-size: contain;
    background-repeat: no-repeat;
    width: 17px;
    height: 17px;
    margin-left: 10px;
  }
`;

export const ArticleCTAWrapper = styled.div`
  ${StyledCTA} {
    max-width: 350px;
    margin: 25px auto;
  }
`;
