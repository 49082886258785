import styled, { css } from 'styled-components';

export const StyledOverlay = styled.div`
  position: fixed;
  top: 0; bottom: 0;
  left: 0; right: 0;
  opacity: 0;
  background: rgba(0,0,0,.6);
  visibility: hidden;
  z-index: 1;

  /* &.underneath-header,
  &.full-cover {
    opacity: 1;
    visibility: visible;
  } */

  ${({ isVisible }) => isVisible && css`
    opacity: 1;
    visibility: visible;
  `}

  /* &.full-cover {
    z-index: 3;
  } */
`;
