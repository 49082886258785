/* eslint-disable import/no-unresolved */
/* eslint-disable no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({
  title, description, image, noIndex,
}) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const {
    defaultTitle,
    titleTemplate,
    googleSiteVerification,
    defaultDescription,
    defaultImage,
    siteUrl,
  } = site.siteMetadata;

  const seo = {
    googleSiteVerification,
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet
      title={seo.title}
      titleTemplate={titleTemplate}
      htmlAttributes={{ lang: 'pl' }}
    >
      <meta name="description" content={seo.description} />
      <meta name="google-site-verification" content={seo.googleSiteVerification} />
      <meta name="image" content={seo.image} />

      {/* Remove page from indexing */}
      {noIndex && <meta name="robots" content="noindex" />}

      {/* OG tags are only for social networking sites or messengers --> */}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && <meta property="og:description" content={seo.description} />}
      {seo.image && <meta property="og:image" content={seo.image} />}
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  noIndex: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  noIndex: false,
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        googleSiteVerification
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`;

export default SEO;
