import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../store/Context';
import { useWindowSize } from '../../../utils/utils';
import Menu from './Menu/Menu';
import Burger from './Burger/Burger';
import { StyledNavigation } from './Navigation.styled';

const Navigation = () => {
  const [width] = useWindowSize();
  const [open, setOpen] = useState(false);
  const [, dispatch] = useContext(Context);
  const desktopWidth = width > 991;

  useEffect(() => {
    setOpen(false);
  }, [desktopWidth]);

  useEffect(() => {
    if (open) {
      document.querySelector('body').classList.add('no-scroll');
      dispatch({ type: 'OVERLAY_SHOW' });
    } else {
      document.querySelector('body').classList.remove('no-scroll');
      dispatch({ type: 'OVERLAY_HIDE' });
    }
  }, [open]);

  return (
    <StyledNavigation aria-label="primary">
      <Menu open={open} setOpen={setOpen} />
      <Burger open={open} setOpen={setOpen} />
    </StyledNavigation>
  );
};

export default Navigation;
