import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { StyledProductCard, StyledProductCardTitle } from './ProductCard.styled';

const ProductCard = ({
  image, alt, heading, ...props
}) => (
  <StyledProductCard {...props}>
    <GatsbyImage image={image} alt={alt} />
    <StyledProductCardTitle>{heading}</StyledProductCardTitle>
  </StyledProductCard>
);

ProductCard.propTypes = {
  image: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired,
  alt: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
};

export default ProductCard;
