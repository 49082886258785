import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { theme } from '../../../styles/theme';
import Container from '../Container/Container';
import {
  StyledPreHeader,
  StyledPreHeaderContent,
  StyledPreHeaderLink,
  StyledPreHeaderIcon,
} from './PreHeader.styled';

const query = graphql`
  query {
    icon1: file(name: { eq: "email-icon" }) {
      publicURL
    }
    icon2: file(name: { eq: "phone-icon" }) {
      publicURL
    }
  }
`;

const PreHeader = () => {
  const data = useStaticQuery(query);

  return (
    <StyledPreHeader>
      <Container>
        <StyledPreHeaderContent>
          <StyledPreHeaderLink href={`mailto:${theme.contacts.email}`}>
            <StyledPreHeaderIcon src={data.icon1.publicURL} alt="email-icon" width="20" height="20" />
            {theme.contacts.email}
          </StyledPreHeaderLink>
          <StyledPreHeaderLink href={`tel:${theme.contacts.phone}`}>
            <StyledPreHeaderIcon src={data.icon2.publicURL} alt="phone-icon" width="20" height="20" />
            {`tel: ${theme.contacts.phone}`}
          </StyledPreHeaderLink>
        </StyledPreHeaderContent>
      </Container>
    </StyledPreHeader>
  );
};

export default PreHeader;
