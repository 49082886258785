import styled from 'styled-components';

export const StyledDownloadFile = styled.section`
  display: inline-flex;
  align-self: flex-start;
  align-items: center;
`;

export const StyledTitle = styled.span`
  font-size: 1.8rem;
  font-weight: 500;
  margin-left: 10px;
`;

export const StyledLink = styled.a`
  background: #ffffff;
  border: solid 1px #e6e6e6;
  border-radius: 2px;
  display: inline-block;
  height: 100px;
  line-height: 100px;
  margin: 5px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 100px;

  span {
    background: #f2594b;
    border-radius: 4px;
    color: #ffffff;
    display: inline-block;
    font-size: 11px;
    font-weight: 700;
    line-height: normal;
    padding: 5px 10px;
    position: relative;
    text-transform: uppercase;
    z-index: 1;

    &:first-child {
      display: none;
    }

    &:last-child {
      margin-left: -20px;
    }
  }

  &::before,
  &::after {
    content: '';
    background: #ffffff;
    border: solid 3px #9fb4cc;
    border-radius: 4px;
    display: block;
    height: 41px;
    left: 50%;
    margin: -17px 0 0 -12px;
    position: absolute;
    top: 50%;
    width: 31px;
  }

  &:hover {
    background: #e2e8f0;
    border-color: #9fb4cc;
    &::before,
    &::after {
      background: #e2e8f0;
    }
    span:first-child {
      display: inline-block;
    }
    span:last-child {
      display: none;
    }
  }

  &::before {
    margin: -23px 0 0 -5px;
  }

  &:active {
    background: #dae0e8;
    box-shadow: inset 0 2px 2px rgba(0, 0, 0, .25);
  }
`;
