import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const ProductCardsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
`;

export const StyledProductCard = styled.figure`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px solid ${({ theme }) => theme.colors.primary};
  box-shadow: 0 4px 24px 0 rgb(29 30 32 / 5%);
  cursor: pointer;
  margin: 0;

  ${breakpoint('md')`
      width: 47%;
  `}

  ${breakpoint('xl')`
      width: 48%;
  `}

  .gatsby-image-wrapper [data-main-image] {
    transition: opacity .25s linear, transform 0.4s ease;
  }

  &:hover .gatsby-image-wrapper [data-main-image] {
    transform: scale(1.08);
  }
`;

export const StyledProductCardTitle = styled.figcaption`
  width: 100%;
  padding: 15px;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: #FFF;
  background-color: ${({ theme }) => theme.colors.primary};
  margin: 0;

  ${breakpoint('lg')`
      font-size: 2.3rem;
      padding: 20px;
  `}
`;
