export const theme = {
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  colors: {
    text: '#22292E',
    primary: '#EA622B',
    secondary: '#F3F4F5',
    tertiary: '#184E77',
  },
  contacts: {
    phone: '516 964 323',
    email: 'biuro@banerbunny.pl',
    email2: 'banerbunny@gmail.com',
    companyName: 'Aesthetic Rafał Zdziarski',
    NIP: 'NIP: 5242703794',
    address: 'Józefa Mehoffera 32/37',
    postalCode: '03-144 Warszawa',
    province: 'woj. mazowieckie',
  },
};
