import React, {
  useRef, useEffect, useCallback, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Context } from '../../../../store/Context';
import { useWindowSize } from '../../../../utils/utils';
import MegaMenu from './MegaMenu/MegaMenu';
import {
  StyledMenu,
  StyledMenuItem,
  StyledMenuDropdown,
  StyledMenuList,
} from './Menu.styled';

const menuLinkList = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Drukarnia',
    path: '/drukarnia/',
  },
  {
    title: 'Projekty Graficzne',
    path: '/projekty-graficzne/',
  },
  {
    title: 'Montaż',
    path: '/montaz/',
  },
  {
    title: 'Produkty',
    path: '/produkty/',
  },
  {
    title: 'Realizacje',
    path: '/realizacje/',
  },
  {
    title: 'Blog',
    path: '/blog/',
  },
  {
    title: 'Kontakt',
    path: '/kontakt/',
  },
];

const Menu = ({ open, setOpen }) => {
  const [width] = useWindowSize();
  const megaMenuRef = useRef(null);
  const productsLinkRef = useRef(null);
  const [state, dispatch] = useContext(Context);

  const handleMegaMenuItemClick = useCallback((event) => {
    const targetNode = event.target.nodeName;
    if (targetNode === ('IMG') || targetNode === ('FIGCAPTION') || targetNode === ('A')) {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // click on products link
      if (productsLinkRef.current && productsLinkRef.current.contains(event.target)) {
        dispatch({ type: 'MEGAMENU_TOGGLE' });
        if (width >= 992) {
          dispatch({ type: 'OVERLAY_TOGGLE' });
        }
      }

      // click on visible mega menu
      if (megaMenuRef.current && megaMenuRef.current.contains(event.target)) {
        dispatch({ type: 'MEGAMENU_SHOW' });
        if (width >= 992) {
          dispatch({ type: 'OVERLAY_SHOW' });
        }
      }

      // click outside mega menu when is visible
      if (state.megaMenuVisible) {
        if (productsLinkRef.current && !productsLinkRef.current.contains(event.target)) {
          dispatch({ type: 'MEGAMENU_HIDE' });
          if (width >= 992) {
            dispatch({ type: 'OVERLAY_HIDE' });
          }
        }
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [state, width]);

  // remove visible overlay and mega menu on resize to mobile
  useEffect(() => {
    if (!open && width < 992) {
      dispatch({ type: 'OVERLAY_HIDE' });
    }
  }, [width]);

  // close the entire menu on mega menu item click
  useEffect(() => {
    document.addEventListener('click', handleMegaMenuItemClick);
    return () => {
      document.removeEventListener('click', handleMegaMenuItemClick);
    };
  }, [handleMegaMenuItemClick]);

  return (
    <StyledMenu open={open}>
      <StyledMenuList>
        {menuLinkList.map(({ title, path }) => (
          <StyledMenuItem key={title}>
            {title === 'Produkty' ? (
              <div ref={productsLinkRef}>
                <StyledMenuDropdown
                  className={state.megaMenuVisible && 'active'}
                >
                  Produkty
                </StyledMenuDropdown>
                <MegaMenu
                  ref={megaMenuRef}
                  isVisible={state.megaMenuVisible}
                />
              </div>
            ) : (
              <Link
                to={path}
                className="menu-link"
                activeClassName="active"
              >
                {title}
              </Link>
            )}
          </StyledMenuItem>
        ))}
      </StyledMenuList>
    </StyledMenu>
  );
};

Menu.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

Menu.defaultProps = {
  open: false,
  setOpen: () => {},
};

export default Menu;
