import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const StyledFigcaption = styled.figcaption`
  font-size: 1.3rem;
  font-weight: 600;
  white-space: initial;
  text-align: center;

  ${breakpoint('lg')`
    font-size: 1.6rem;
  `}
`;

export const StyledFigure = styled.figure`
  min-width: 140px;
  max-width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin: 0;
  color: ${({ theme }) => theme.colors.text};
  text-transform: capitalize;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${breakpoint('lg')`
    max-width: 100%;
  `}

  .gatsby-image-wrapper {
    margin-bottom: 15px;

    ${breakpoint('lg')`
      max-width: 170px;
    `}
  }

  ${({ productCategoryPage }) => productCategoryPage && css`
    margin: 0;
    flex: auto;
    max-width: 100%;

    ${breakpoint('md')`
      width: 45%;
    `}

    .gatsby-image-wrapper {
      max-width: 350px;
      width: 100%;
    }

    ${StyledFigcaption} {
      font-size: 1.8rem;
      text-align: center;

      ${breakpoint('lg')`
        font-size: 2rem;
      `}

      ${breakpoint('xl')`
        font-size: 2.3rem;
      `}
    }
  `}

  ${({ smaller }) => smaller && css`
    .gatsby-image-wrapper {
      ${breakpoint('lg')`
        max-width: 130px;
      `}
    }
  `}
`;
