import styled, { keyframes } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const grow = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.15); }
  100% { transform: scale(1); }
`;

export const StyledContactIcon = styled.img`
  width: 100%;
  max-width: 60px;
  margin: 6px auto 25px;

  ${breakpoint('md')`
    max-width: 70px;
    margin: 6px auto 30px;
  `}

  ${breakpoint('lg')`
    margin: 6px auto 35px;
  `}
`;

export const StyledContactWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 25px;

  ${breakpoint('md')`
    grid-template-columns: 1fr 1fr;
  `}

  ${breakpoint('xl')`
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `}
`;

export const StyledContactBox = styled.div`
  width: 100%;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 20px 25px; 
  background-color: #FFF;
  box-shadow: 0 4px 24px 0 rgb(29 30 32 / 5%);

  &:hover {
    ${StyledContactIcon} {
      animation: ${grow} 1.6s infinite ease-in-out;
    }
  }

  // baner bunny logo in first item
  &.company ${StyledContactIcon} {
    max-width: 155px;
  }
`;

export const StyledContactText = styled.span`
  width: 100%;
  margin: 0 auto 6px;
  color: #706F6F;
  font-weight: 600;
  transition: color .2s ease;
  white-space: pre;

  ${breakpoint('lg')`
    font-size: 1.8rem;
  `}
`;

export const StyledContactLink = styled(StyledContactText).attrs({
  as: 'a',
})`
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const StyledContactTitle = styled.h3`
  width: 100%;
  margin: 0 auto 15px;
  text-align: center;
  font-size: 1.6rem;

  ${breakpoint('lg')`
    font-size: 1.8rem;
  `}
`;
