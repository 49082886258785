import React from 'react';
import PropTypes from 'prop-types';
import { StyledWrapper } from './Wrapper.styled';

const Wrapper = ({ backgroundColor, children, noPadding }) => (
  <StyledWrapper
    backgroundColor={backgroundColor}
    noPadding={noPadding}
  >
    {children}
  </StyledWrapper>
);

Wrapper.propTypes = {
  noPadding: PropTypes.bool,
  backgroundColor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Wrapper.defaultProps = {
  noPadding: false,
  backgroundColor: 'white',
};

export default Wrapper;
