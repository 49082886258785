import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const StyledArticle = styled.article`
  width: 100%;
  margin-bottom: 20px;

  ${breakpoint('md')`
    margin-bottom: 25px;
  `}

  ${breakpoint('lg')`
    margin-bottom: 30px;
  `}

  &:last-child {
    margin: 0;
  }
`;
