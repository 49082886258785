import { createGlobalStyle } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%; // 1rem === 10px
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  body {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    min-height: -webkit-fill-available;
    height: calc(var(--vh, 1vh) * 100);
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.secondary};
    overflow-x: hidden;
    margin: 0;
    padding: 0;

    &.no-scroll {
      overflow-y: hidden;
    }
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  button {
    padding: 0;
    cursor: pointer;
    font-family: 'Montserrat';
  }

  p {
    font-size: 1.6rem;
    margin: 0 0 20px;
    text-align: justify;

    ${breakpoint('lg')`
      font-size: 1.8rem;
    `}

    a {
      display: inline-block;
      &:hover {
        text-decoration: underline;
      }
    }

    &.empty-line {
      margin: -5px 0;
    }
  }

  ul, ol {
    margin: 0;
    padding-left: 30px;
  }

  ul {
    list-style-type: none;
  }

  ol {
    list-style-type: decimal;
  }

  a {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
  }

  strong, b {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary};
  }

  h1, h2, h3, h4, h5, h6, 
  article > p:first-child:not(.thin) {
    font-weight: 700;
    line-height: 1.3;
    margin: 0;
    text-align: left;
    text-transform: inherit;
  }

  h1 {
    font-size: 2rem;
    margin: 25px 0;

    ${breakpoint('lg')`
      font-size: 2.2rem;
      margin: 35px 0;
    `}
  }

  h2, article > p:first-child:not(.thin) {
    font-size: 1.8rem;
    margin-bottom: 10px;

    ${breakpoint('lg')`
      font-size: 2rem;
    `}
  }

  .black {
    color: ${({ theme }) => theme.colors.text};
  }

  .orange {
    color: ${({ theme }) => theme.colors.primary};
  }

  .green {
    color: #39c161;
    font-size: 2rem;
  }

  .center {
    display: block;
    text-align: center;
  }

  .anchor-link {
    padding: 3px 5px;
    font-size: 1.8rem;
    background: linear-gradient(to left, transparent 50%, #184E77 50%);
    background-size: 200%;
    background-position: right;
    color: ${({ theme }) => theme.colors.primary};
    transition: background-position .3s ease-in-out;
    &:hover {
      background-position: left;
    }
    ${breakpoint('lg')`
      font-size: 2rem;
    `}
  }

  .gatsby-image-wrapper {
    cursor: pointer;
  }

  .open-pdf-link {
    margin: 0;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.text};
    text-decoration: underline;
  }

  .eco-options-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    ${breakpoint('md')`
      flex-direction: row;
    `}

    img {
      width: 100px;
    }
  }

  .cookies-container {
    width: auto !important;
    display: flex;
    flex-wrap: nowrap !important;
    flex-direction: column;
    align-items: center !important;
    background: #2B373B;
    margin: 10px;
    padding: 5px;

    ${breakpoint('md')`
      flex-direction: row;
      width: 80% !important;
    `}
  }

  .cookies-text {
    flex: auto !important;
    font-size: 1.4rem;

    ${breakpoint('md')`
      font-size: 1.6rem;
    `}

    & + div {
      width: 100%;
      padding: 15px;

      ${breakpoint('md')`
        min-width: 150px;
        margin-left: 20px;
        flex: 1;
      `}
    }

    a {
      text-decoration: underline;
      white-space: pre;
    }
  }

  .cookies-button {
    width: 100%;
    margin: 0 !important;
    padding: 12px 20px !important;
    font-weight: bold;
  }

  #callpageWrapper {
    #callpage {
      .cp-button-section {
        z-index: 900 !important;
      }
      .cp-widget-button {
        right: -15px !important;
        bottom: -15px !important;
      }
    }
  }
`;

export default GlobalStyle;
