import styled, { css } from 'styled-components';
import ArrowRightIcon from '../../../assets/images/icons/arrow-right-icon.svg';

export const StyledButton = styled.button`
  position: relative;
  display: inline-block;
  text-align: center;
  text-transform: inherit;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.8rem;
  margin: 0;
  padding: 12px 20px 14px;
  border: 2px solid;
  border-radius: 40px;
  letter-spacing: .5px;
  box-shadow: 0 8px 24px 0 rgb(255 135 67 / 36%);
  transition: background-color .3s ease, color .3s ease;
  white-space: pre-line;
  overflow: hidden;
  cursor: pointer;
  outline: none;
  
  ${({ primary }) => primary && css`
    border-color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.primary};
    color: #FFF;
    &:hover {
      background-color: #FFF;
      color: ${({ theme }) => theme.colors.primary};
    }
  `}

  ${({ secondary }) => secondary && css`
    border-color: ${({ theme }) => theme.colors.primary};
    background-color: #FFF;
    color: ${({ theme }) => theme.colors.primary};
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
      color: #FFF;
    }
  `}

  ${({ megaMenu }) => megaMenu && css`
    border: 0;
    text-align: left;
    text-transform: capitalize;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.text};
    padding: 11px 22px 12px;
    font-size: 1.6rem;
    box-shadow: none;
    transition: none;
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  `}

  ${({ arrowActive }) => arrowActive && css`
    color: #FFF;
    background-color: ${({ theme }) => theme.colors.primary};
    &:hover {
      color: #FFF;
    }

    &::after {
      content: '';
      width: 20px;
      height: 15px;
      margin-left: 15px;
      position: absolute;
      top: 50%;
      right: 22px;
      transform: translateY(-50%);
      background-image: url(${ArrowRightIcon});
      background-size: contain;
      background-repeat: no-repeat;
    }
  `}
`;
