import React from 'react';
import PropTypes from 'prop-types';
import { StyledArticle } from './Article.styled';

const Article = ({ children }) => (
  <StyledArticle>
    {children}
  </StyledArticle>
);

Article.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Article;
